import * as msal from '@azure/msal-browser';
import * as Sentry from '@sentry/nextjs';
import { Configuration } from '@azure/msal-browser';

import { config } from './config';

const msalConfig: Configuration = {
  auth: {
    clientId: config.clientId,
    authority: `https://login.microsoftonline.com/${config.tenantId}`,
    redirectUri:
      (typeof window !== 'undefined' && window?.location?.origin) || '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: config.azureCache,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string): void => {
        if (level !== msal.LogLevel.Error) {
          return;
        }

        Sentry.captureException(message);
      },
    },
  },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
