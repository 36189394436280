import React from 'react';
import { MsalAuthenticationResult } from '@azure/msal-react';

import Alert from '@airnz/ui/Alert';
import PageBlock from '@airnz/ui/PageBlock';

import Layout from '../Layout';

const AuthenticationError = (authErrorResult: MsalAuthenticationResult) => (
  <Layout>
    <PageBlock>
      <Alert type="danger">
        {`An authentication error has occurred. Please refresh and try again, or contact the administrator if the problem persists. ${
          authErrorResult.error && `(Code: ${authErrorResult.error.errorCode})`
        }`}
      </Alert>
    </PageBlock>
  </Layout>
);

export default AuthenticationError;
