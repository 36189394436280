import React, { createContext, useContext, useEffect, useState } from 'react';

import useFetchBanner from '@modules/FlightPlanAdmin/hooks/useFetchBanner';

import type { BannerMessage } from '@modules/FlightPlanAdmin/types';

type Props = {
  children: React.ReactNode;
};

const defaultValue = {
  bannerHyperLinkText: '',
  bannerHyperLinkTextUrl: '',
  bannerText: '',
};

const BannerContext = createContext<BannerMessage>(defaultValue);

export const useBannerContext = () => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export const BannerProvider = ({ children }: Props) => {
  const { bannerMessages, fetchStatus } = useFetchBanner();

  const [value, setValue] = useState<BannerMessage>(defaultValue);

  useEffect(() => {
    if (fetchStatus === 'success') {
      setValue(bannerMessages);
    }
  }, [bannerMessages, fetchStatus]);

  return (
    <BannerContext.Provider value={{ ...value, refreshBanner: setValue }}>
      {children}
    </BannerContext.Provider>
  );
};
