import React, { useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { ThemeContext } from 'styled-components';
import { isEmpty } from 'lodash';

import HeaderBase from '@airnz/ui/HeaderBase';
import InternalAppHeaderLogo from '@airnz/ui/InternalAppHeaderLogo';
import HeaderLink from '@airnz/ui/HeaderLink';

import useUser from '@hooks/useUser';

import HeaderUserPopover from '../HeaderUserPopover';
import { Banner } from '@common/Banner';

import { useBannerContext } from '@common/BannerProvider';

interface HeaderProps {
  hideNav?: boolean;
  showBanner?: boolean;
}

const Header = ({ hideNav, showBanner }: HeaderProps) => {
  const {
    breakpoints: { lg },
  } = useContext(ThemeContext);
  const { instance } = useMsal();
  const user = useUser();

  const { bannerHyperLinkText, bannerHyperLinkTextUrl, bannerText } =
    useBannerContext();

  // If either banner text or hyper link text is not empty, we should see banner.
  const isBannerVisible =
    showBanner && (!isEmpty(bannerText) || !isEmpty(bannerHyperLinkText));

  const logo = (
    <InternalAppHeaderLogo to="/" size="sm">
      Flight Plan Portal
    </InternalAppHeaderLogo>
  );

  const navItems = hideNav ? null : (
    <>
      <HeaderLink to="/">Flight Plans</HeaderLink>
      <HeaderLink to="/weather" openInNew>
        Weather
      </HeaderLink>
      <HeaderLink
        to="https://airnewzealand.sharepoint.com/sites/e001/elibrary/default.aspx"
        openInNew
      >
        Manuals
      </HeaderLink>
      <HeaderLink to="/support">Support / Release Timing </HeaderLink>
      {user?.canSeeAdminPage && <HeaderLink to="/admin">Admin </HeaderLink>}
    </>
  );

  const actionItems = user && (
    <HeaderUserPopover
      userName={user.name || ''}
      onSignOut={() => instance.logoutRedirect()}
    />
  );

  return (
    <>
      {isBannerVisible ? (
        <Banner
          text={bannerText}
          hyperLinkText={bannerHyperLinkText}
          hyperLinkTextUrl={bannerHyperLinkTextUrl}
        />
      ) : null}
      <HeaderBase
        topLeft={
          lg ? (
            <>
              {logo} {navItems}
            </>
          ) : (
            logo
          )
        }
        topRight={lg ? actionItems : null}
        bottomLeft={lg ? null : navItems}
        bottomRight={lg ? null : actionItems}
      />
    </>
  );
};

export default Header;
