import { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';

import apiRequest from '@api/apiRequest';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';

import type { BannerMessage } from '@modules/FlightPlanAdmin/types';
import type { StatusType } from '@common/types';

import { bannerUrl } from '@utils/apiUrls';
import { useApiScopes } from '@common/ApiProvider';

export const useFetchBanner = () => {
  const { instance } = useMsal();
  const bannerScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  const [fetchStatus, setFetchStatus] = useState<StatusType>('idle');
  const [bannerMessages, setBannerMessages] = useState<BannerMessage>(
    {} as BannerMessage
  );

  useEffect(() => {
    const fetchBannerMessage = async () => {
      try {
        setFetchStatus('pending');

        const accessToken = await getAzureAccessToken(instance, bannerScopes);
        const response = await apiRequest<{ bannerMessageDto: BannerMessage }>({
          method: 'GET',
          url: bannerUrl,
          headers: {
            Authorization: accessToken,
          },
        });

        setBannerMessages(response.bannerMessageDto);
        setFetchStatus('success');
      } catch {
        setFetchStatus('failure');
      }
    };

    fetchBannerMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return {
    bannerMessages,
    fetchStatus,
  };
};

export default useFetchBanner;
