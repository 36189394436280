// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { redactPII } from '@utils/sentry/redact';

let environment = process.env.NODE_ENV;

if (window.location.href.includes('flightplan-t')) {
  environment = 'test';
}

if (window.location.href.includes('flightplan-q')) {
  environment = 'qual';
}

Sentry.init({
  enabled: process.env.SENTRY_ENABLED === 'true',
  dsn: process.env.SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  beforeSend: redactPII,
  environment,
});
