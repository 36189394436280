import React, { useContext, useMemo } from 'react';

export type ApiScopeBuilder = (
  apiClientId: string,
  permission: string
) => string;

export interface ApiContextType {
  apiConfig: {
    [x: string]: {
      clientId: string;
    };
  };
  apiScopeBuilder: ApiScopeBuilder;
}

const ApiContext = React.createContext<ApiContextType | undefined>(undefined);

export const useApiScopes = (
  apiCode: any,
  permissions: string[]
): string[] | undefined => {
  const apiContext = useContext(ApiContext);
  return useMemo(
    () =>
      apiContext
        ? permissions.map(permission =>
            apiContext.apiScopeBuilder(
              apiContext.apiConfig[apiCode].clientId,
              permission
            )
          )
        : undefined,
    [apiContext, apiCode, permissions]
  );
};

export interface ApiProviderProps extends ApiContextType {
  children: React.ReactNode;
}

export const ApiProvider = ({
  children,
  apiConfig,
  apiScopeBuilder,
}: ApiProviderProps) => (
  <ApiContext.Provider
    value={{
      apiConfig,
      apiScopeBuilder,
    }}
  >
    {children}
  </ApiContext.Provider>
);
