import React, { FC } from 'react';
import styled from 'styled-components';
import Head from 'next/head';

import AppRoot from '@airnz/ui/AppRoot';
import Footer from '@airnz/ui/Footer';

import Header from '../Header';

interface LayoutProps {
  title?: string;
  hideNav?: boolean;
  showBanner?: boolean;
}

const FullHeightAppRoot = styled(AppRoot)`
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
`;

const Layout: FC<LayoutProps> = ({
  children,
  title,
  hideNav,
  showBanner = false,
}) => (
  <>
    <Head>
      <title>{title || 'Flight Plan Portal'}</title>
      <meta name="referrer" content="origin" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>

    <FullHeightAppRoot>
      <Header hideNav={hideNav} showBanner={showBanner} />
      <Main>{children}</Main>
      <Footer />
    </FullHeightAppRoot>
  </>
);

export default Layout;
