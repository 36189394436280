import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import IconText from '@airnz/ui/IconText';
import Danger from '@airnz/ui/icons/Danger';
import TextLink from '@airnz/ui/TextLink';
import { ColorModeProvider } from '@airnz/ui/ColorMode';

interface BannerProps {
  text: string;
  hyperLinkText: string;
  hyperLinkTextUrl: string;
}

const StyledBanner = styled.div`
  ${({ theme: { spaces, palette } }) => css`
    display: flex;
    background-color: ${palette.crimsonPrimary};
    padding: ${spaces.md};
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  `}
`;

const StyledInner = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  word-break: break-word;
`;

const Span = styled.span`
  ${({ theme: { spaces } }) => css`
    margin-left: ${spaces.sm};
  `};
`;

export const Banner: FC<BannerProps> = ({
  text,
  hyperLinkText,
  hyperLinkTextUrl,
}) => (
  <ColorModeProvider onDark onColor>
    {({ colorModeClassName }) => (
      <StyledBanner className={colorModeClassName}>
        <StyledInner>
          <IconText icon={<Danger />}>
            {text}
            <Span>
              <TextLink openInNew to={hyperLinkTextUrl}>
                {hyperLinkText}
              </TextLink>
            </Span>
          </IconText>
        </StyledInner>
      </StyledBanner>
    )}
  </ColorModeProvider>
);
