import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { ErrorBoundary } from '@sentry/nextjs';

import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import Alert from '@airnz/ui/Alert';
import LoadingSpinner from '@airnz/ui/LoadingSpinner';
import Paragraph from '@airnz/ui/Paragraph';
import Button from '@airnz/ui/Button';
import PageBlock from '@airnz/ui/PageBlock';
import Dialog from '@airnz/ui/Dialog';

import { ApiProvider } from '@common/ApiProvider';
import Layout from '@common/Layout';
import AuthenticationError from '@common/AuthenticationError';
import { config } from '@api/services/config';
import { BannerProvider } from '@common/BannerProvider';

import { msalInstance } from '@api/services/msalInstance';
import { apiScopeBuilder } from '@api/services/apiScopeBuilder';

import '@airnz/ui/fonts';

const apiConfig = {
  flightPlan: {
    clientId: config.apiClientId,
  },
};

const loginRequest = {
  scopes: [
    `api://${config.apiClientId}/fuel.order`,
    `api://${config.apiClientId}/flight.plan`,
    `User.Read`,
    `profile`,
  ],
};

const BriefingApp = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      {/* eslint-disable react/self-closing-comp */
      /* eslint-disable @next/next/no-sync-scripts */}
      <script type="text/javascript" src="/config/env.js"></script>

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
    </Head>

    <ErrorBoundary
      fallback={({ error, resetError }) => (
        <Layout title={pageProps.title} hideNav>
          <PageBlock size="lg">
            <Dialog
              heading="It looks like we're having some issues."
              actions={
                <Button primary onClick={resetError}>
                  Reload Application
                </Button>
              }
            >
              <Alert type="danger">
                <Paragraph>
                  The application encountered an error:{' '}
                  <strong>{error.message}</strong>
                </Paragraph>
              </Alert>

              <Paragraph>
                An error report has been automatically submitted and the
                development team have been notified.
              </Paragraph>
            </Dialog>
          </PageBlock>
        </Layout>
      )}
    >
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={AuthenticationError}
          loadingComponent={() => <LoadingSpinner />}
        >
          <ApiProvider apiConfig={apiConfig} apiScopeBuilder={apiScopeBuilder}>
            <BannerProvider>
              <Layout title={pageProps.title} showBanner>
                <Component {...pageProps} />
              </Layout>
            </BannerProvider>
          </ApiProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </ErrorBoundary>
  </>
);

export default BriefingApp;
