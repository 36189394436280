import React from 'react';

import { default as AirnzUiHeaderUserPopover } from '@airnz/ui/HeaderUserPopover';
import HeaderUserPopoverGreeting from '@airnz/ui/HeaderUserPopoverGreeting';
import HeaderUserPopoverSection from '@airnz/ui/HeaderUserPopoverSection';
import Button from '@airnz/ui/Button';
import useToggle from '@airnz/ui/useToggle';

interface HeaderUserPopoverProps {
  initialOpen?: boolean;
  userName: string;
  onSignOut(): void;
}

const HeaderUserPopover = ({
  initialOpen,
  userName,
  onSignOut,
}: HeaderUserPopoverProps) => {
  const [open, toggleOpen] = useToggle(initialOpen || false);
  const givenName = userName.split(' ')[0];
  return (
    <AirnzUiHeaderUserPopover
      open={open}
      userName={userName}
      onOpen={toggleOpen}
      onClose={toggleOpen}
    >
      <HeaderUserPopoverSection>
        <HeaderUserPopoverGreeting name={givenName} />
      </HeaderUserPopoverSection>
      <HeaderUserPopoverSection>
        <Button size="sm" onClick={onSignOut}>
          Sign out
        </Button>
      </HeaderUserPopoverSection>
    </AirnzUiHeaderUserPopover>
  );
};

export default HeaderUserPopover;
