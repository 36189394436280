import * as Sentry from '@sentry/nextjs';

interface Headers {
  [key: string]: string | undefined;
}

export interface RequestOptions {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT';
  body?: any;
  headers?: Headers;
  credentials?: 'same-origin' | 'omit' | 'include';
}

const filterHeaders = (headers: Headers = {}) =>
  Object.entries(headers).reduce(
    (acc, [key, value]) =>
      value !== undefined ? { ...acc, [key]: value } : acc,
    {}
  );

const request = async <T = void>(options: RequestOptions): Promise<T> => {
  const headers = new Headers(
    filterHeaders({
      Accept: 'application/json',
      'Content-Type': options.body
        ? 'application/json; charset=utf-8'
        : undefined,
      ...options.headers,
    })
  );

  const response = await fetch(options.url, {
    headers,
    method: options.method,
    credentials: options.credentials,
    body: options.body ? JSON.stringify(options.body) : undefined,
  });

  const hasNoContent = response.status === 204;

  let responseBody;

  if (!hasNoContent) {
    try {
      responseBody = await response.json();
    } catch {
      const message = `Could not parse response from ${options.url} as JSON. Response status ${response.status}`;
      Sentry.captureException(message);
      throw new Error(message);
    }
  }

  if (!response.ok) {
    Sentry.captureException(responseBody.message);
    throw new Error('An error occurred while processing the request.');
  }

  return responseBody;
};

export default request;
