import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';

type UserRole =
  | 'f-flight-plan-portal-read-access'
  | 'f-flight-plan-portal-fuel-docket-read-write-access'
  | 'f-flight-plan-portal-admin'
  | 'f-flight-plan-portal-ground-ops';

// AccountInfo types idTokenClaims as a generic "object"
interface User extends AccountInfo {
  idTokenClaims?: {
    roles: UserRole[];
  };
}

const useUser = (): {
  name?: string;
  canOrderFuel: boolean;
  canOrderFuelReadOnly: boolean;
  canSeeAdminPage: boolean;
  canSeeDraftPlans: boolean;
} | null => {
  const { accounts } = useMsal();

  if (!accounts || accounts.length === 0) {
    return null;
  }

  const { name, idTokenClaims } = accounts[0] as User;
  const userRoles = idTokenClaims?.roles ?? [];

  const canOrderFuel = userRoles.includes(
    'f-flight-plan-portal-fuel-docket-read-write-access'
  );

  const canOrderFuelReadOnly = userRoles.includes(
    'f-flight-plan-portal-read-access'
  );

  // Only admin can see admin page
  const canSeeAdminPage = userRoles.includes('f-flight-plan-portal-admin');

  // Only flight planners and Jet pilots can see draft plans
  const canSeeDraftPlans =
    userRoles.includes('f-flight-plan-portal-fuel-docket-read-write-access') ||
    userRoles.includes('f-flight-plan-portal-ground-ops') ||
    userRoles.includes('f-flight-plan-portal-read-access');

  return {
    name,
    canOrderFuel,
    canOrderFuelReadOnly,
    canSeeAdminPage,
    canSeeDraftPlans,
  };
};

export default useUser;
