/**
 * PII redaction is a mandatory InfoSec control
 *
 * This is not an exhaustive implementation and is to a certain degree comparable to the basic
 * Server-Side Data Scrubbing feature provided by Sentry
 *
 * See https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/
 *
 * Possible long term solution is to leverage Sentry Relay for advanced data scrubbing configuration
 *
 * See https://docs.sentry.io/product/relay/
 *
 * In the meantime, custom data (eg. user, contexts, tags, extras) will be omitted from the event
 */

import { Event } from '@sentry/react';
import omit from 'lodash-es/omit';
import flow from 'lodash-es/flow';

const REDACTED_PLACEHOLDER = '[REDACTED]';

const EMAIL_REGEX = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
const IP_REGEX =
  /\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/g;
const CREDIT_CARD_REGEX = /\b(?:\d[ -]*?){13,16}\b/g;

const redactText = (text: string, regex: RegExp): string =>
  text.replace(regex, REDACTED_PLACEHOLDER);

const redactEmailAddresses = (text: string): string =>
  redactText(text, EMAIL_REGEX);

const redactIPAddresses = (text: string): string => redactText(text, IP_REGEX);

const redactCreditCardNumbers = (text: string): string =>
  redactText(text, CREDIT_CARD_REGEX);

const redactPIISubstring = (text: string): string =>
  flow(redactEmailAddresses, redactIPAddresses, redactCreditCardNumbers)(text);

export const redactPII = (event: Event): Event => {
  const { message, exception, ...rest } = omit(event, [
    'user',
    'contexts',
    'tags',
    'extra',
  ]);

  const redactedMessage = message && redactPIISubstring(message);

  const redactedExceptionValues =
    exception?.values?.map(val => ({
      ...val,
      value: val.value ? redactPIISubstring(val.value) : undefined,
    })) || undefined;

  return {
    ...rest,
    message: redactedMessage,
    exception: exception
      ? {
          values: redactedExceptionValues,
        }
      : undefined,
  };
};
