declare global {
  interface Window {
    appConfig: AppConfig;
  }
}

export interface AppConfig {
  TENANT_ID: string;
  CLIENT_ID: string;
  API_CLIENT_ID: string;
  AZURE_CACHE: 'localStorage' | 'sessionStorage';
}

export interface AppWindow {
  appConfig?: AppConfig;
}

export const appConfig: AppConfig =
  typeof window !== 'undefined'
    ? window?.appConfig
    : {
        TENANT_ID: '',
        CLIENT_ID: '',
        API_CLIENT_ID: '',
        AZURE_CACHE: 'localStorage',
      };
