import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

export const getAzureAccessToken = async (
  msalInstance: IPublicClientApplication,
  scopes: Array<string> = []
): Promise<string | undefined> => {
  const account = msalInstance.getAllAccounts()[0];
  const request = {
    account,
    scopes,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    if (response && response.account) {
      return response.accessToken;
    }
    throw new Error(
      'An authenticated account is required to retrieve an access token.'
    );
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(request);
    }
    throw error;
  }
};
